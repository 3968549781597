import React, { useEffect, useState } from 'react';

function Infinitemarquee() {
  const [brands, setBrands] = useState([]);

  // Fetching the data from the API using fetch
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch('https://apiuser.ride2luxury.in/api/brands');
        const data = await response.json(); // Parsing the JSON response
        if (data.status) {
          setBrands(data.brands);  // Store fetched brands in state
        }
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    fetchBrands();
  }, []);

  return (
    <marquee>
      <div className="de-marquee-list d-marquee-small">
        <div className="d-item">
          {/* Map through the fetched brands and display them */}
          {brands.map((brand) => (
            <React.Fragment key={brand.id}>
              {/* <span className="d-item-txt">{brand.brand_name}</span> */}
              <img className="d-item-txt" src={brand.brand_image} alt="image" height={80}/>
              <span className="d-item-display">
                <i className="d-item-dot" />
              </span>
            </React.Fragment>
          ))}
        </div>
      </div>
    </marquee>
  );
}

export default Infinitemarquee;
