import React, { useEffect, useState } from 'react';

function OurVehicle() {
    const [vehicles, setVehicle] = useState([]);

    // Fetching the data from the API using POST method
    useEffect(() => {
        const fetchVehicle = async () => {
            try {
                const response = await fetch('https://apiuser.ride2luxury.in/api/vehicles', {
                    method: 'POST', // Using POST method
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        // You can pass any required data in the body here (if needed)
                    }),
                });
                const data = await response.json(); // Parsing the JSON response

                console.log('Data:', data);
                if (data.status) {
                    setVehicle(data.VEHICLES);  // Store fetched vehicles in state
                }
            } catch (error) {
                console.error('Error fetching vehicles:', error);
            }
        };

        fetchVehicle();
    }, []);

    return (
        <>
            <section id="section-cars">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 offset-lg-3 text-center">
                            <span className="subtitle">Enjoy Your Ride</span>
                            <h2><strong>Our Vehicle Fleet</strong></h2>
                            <p>
                                Driving your dreams to reality with an exquisite fleet of
                                versatile vehicles for unforgettable journeys.
                            </p>
                            <div className="spacer-30" />
                        </div>
                        <div className="clearfix" />
                        <div id="items-carousel" className="owl-carousel wow fadeIn">
                            {vehicles.map((vehicle) => (
                                <React.Fragment key={vehicle.id}>
                                    <div className="col-lg-12">
                                        <div className="de-item mb30">
                                            <div className="d-img">
                                                <img src={vehicle.vehicle_image} className="img-fluid" alt="" />
                                            </div>
                                            <div className="d-info">
                                                <div className="d-text">
                                                    <h4>{vehicle.model}</h4>
                                                    <div className="d-item_like">
                                                        <i className="fa fa-heart" />
                                                        <span>74</span>
                                                    </div>
                                                    <div className="d-atr-group">
                                                        <span className="d-atr">
                                                            <img src="images/icons/1-green.svg" alt="" />5
                                                        </span>
                                                        <span className="d-atr">
                                                            <img src="images/icons/2-green.svg" alt="" />2
                                                        </span>
                                                        <span className="d-atr">
                                                            <img src="images/icons/3-green.svg" alt="" />4
                                                        </span>
                                                        <span className="d-atr">
                                                            <img src="images/icons/4-green.svg" alt="" />SUV
                                                        </span>
                                                    </div>
                                                    <div className="d-price">
                                                        Daily rate from <span>$265</span>
                                                        <a className="btn-main" href="/Carsingle">
                                                            Rent Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OurVehicle;
