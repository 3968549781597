import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
// import Marquee from '../Components/Marquee'
import Infinitemarquee from '../Components/Infinitemarquee'
import OurvVhicle from '../OurvVhicle/OurvVhicle'
import Faq from '../Components/Faq'

function Home() {
  return (
    <>  

     <Header/>
    {/* content begin */}
    <div className="no-bottom " id="content">
      <div id="top" />
      
     
      <section aria-label="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 offset-lg-1 text-center">
            <img src="images/showcase/ride2luxury.png" alt="" className="img-fluid wow fadeInUp " width="300px"/>
              <div className="spacer-30">

              </div>
              <p className="">
              Discover a world of convenience, safety, and customization, paving the way for unforgettable adventures and seamless mobility solutions.
              </p>
              <br></br>
              <br></br>
            <div className="spacer-20" />
             </div>
            <div className="clearfix" />
              <div className="col-lg-2">
                
              </div>
              <div className="col-lg-8">
                <img
                  src="images/showcase/luxuryCars.png"
                  alt=""
                  className="img-fluid wow fadeInUp"
                />
              </div>
              <div className="col-lg-2">
                
              </div>
              <div class="spacer-30"></div>

              <div className="col-lg-6">
                <div
                  className="box-icon s2 p-small mb20 wow fadeInRight"
                  data-wow-delay=".5s"
                >
                  <i className="fa bg-color fa-trophy" />
                  <div className="d-inner">
                    <h4>First class services</h4>
                    Where luxury meets exceptional care, creating unforgettable
                    moments and exceeding your every expectation.
                  </div>
                </div>
                <div
                  className="box-icon s2 p-small mb20 wow fadeInL fadeInRight"
                  data-wow-delay=".75s"
                >
                  <i className="fa bg-color fa-road" />
                  <div className="d-inner">
                    <h4>24/7  assistance</h4>
                    Reliable support when you need it most, keeping you on the
                    move with confidence and peace of mind.
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
                  data-wow-delay="1s"
                >
                  <i className="fa bg-color fa-tag" />
                  <div className="d-inner">
                    <h4>Quality at Minimum Expense</h4>
                    Unlocking affordable brilliance with elevating quality while
                    minimizing costs for maximum value.
                  </div>
                </div>
                <div
                  className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
                  data-wow-delay="1.25s"
                >
                  <i className="fa bg-color fa-map-pin" />
                  <div className="d-inner">
                    <h4>Free Pick-Up &amp; Drop-Off</h4>
                    Enjoy free pickup and drop-off services, adding an extra layer
                    of ease to your car rental experience.
                  </div>
                </div>
              </div>
            </div>
            
        </div>
        <section aria-label="section" className="pt40 pb40 text-light" data-bgcolor="#111111">
        {/* Marquee */}
        {/* <Marquee/> */}
        <Infinitemarquee/>
      </section>
      </section>
      <section className="text-light jarallax">
        <img src="images/background/2.jpg" className="jarallax-img" alt="" />
        <div className="container">
        <div className="row">
              <div className="col-lg-12 text-light">
                <div className="container-timeline">
                  <ul>
                    <li>
                      <h4>Choose a vehicle</h4>
                      <p>
                        Unlock unparalleled adventures and memorable journeys
                        with our vast fleet of vehicles tailored to suit every
                        need, taste, and destination.
                      </p>
                    </li>
                    <li>
                      <h4>Pick location &amp; date</h4>
                      <p>
                        Pick your ideal location and date, and let us take you
                        on a journey filled with convenience, flexibility, and
                        unforgettable experiences.
                      </p>
                    </li>
                    <li>
                      <h4>Make a booking</h4>
                      <p>
                        Secure your reservation with ease, unlocking a world of
                        possibilities and embarking on your next adventure with
                        confidence.
                      </p>
                    </li>
                    <li>
                      <h4>Sit back &amp; relax</h4>
                      <p>
                        Hassle-free convenience as we take care of every detail,
                        allowing you to unwind and embrace a journey filled
                        comfort.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
        
      </section>

      <OurvVhicle/>
      
      <section className="text-light jarallax" aria-label="section">
        <img src="images/background/3.jpg" alt="" className="jarallax-img" />
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInRight">
              <h2>
                We offer customers a wide range of{" "}
                <span className="id-color">commercial cars</span> and{" "}
                <span className="id-color">luxury cars</span> for any occasion.
              </h2>
            </div>
            <div className="col-lg-6 wow fadeInLeft">
              At our car rental agency, we believe that everyone deserves to
              experience the pleasure of driving a reliable and comfortable
              vehicle, regardless of their budget. We have curated a diverse
              fleet of well-maintained cars, ranging from sleek sedans to
              spacious SUVs, all at competitive prices. With our streamlined
              rental process, you can quickly and conveniently reserve your
              desired vehicle. Whether you need transportation for a business
              trip, family vacation, or simply want to enjoy a weekend getaway,
              we have flexible rental options to accommodate your schedule.
            </div>
          </div>
          <div className="spacer-double" />
          <div className="row text-center">
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count bg-dark text-light wow fadeInUp">
                <h3 className="timer" data-to={15425} data-speed={3000}>
                  0
                </h3>
                Completed Orders
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count bg-dark text-light wow fadeInUp">
                <h3 className="timer" data-to={8745} data-speed={3000}>
                  0
                </h3>
                Happy Customers
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count bg-dark text-light wow fadeInUp">
                <h3 className="timer" data-to={235} data-speed={3000}>
                  0
                </h3>
                Vehicles Fleet
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count bg-dark text-light wow fadeInUp">
                <h3 className="timer" data-to={15} data-speed={3000}>
                  0
                </h3>
                Years Experience
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section-news">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 offset-lg-3 text-center">
              <span className="subtitle">Latest From Us</span>
              <h2>News &amp; Promo</h2>
              <p>
                Breaking news, fresh perspectives, and in-depth coverage - stay
                ahead with our latest news, insights, and analysis.
              </p>
              <div className="spacer-20" />
            </div>
            <div className="col-lg-4 mb10">
              <div className="bloglist s2 item">
                <div className="post-content">
                  <div className="post-image">
                    <div className="date-box">
                      <div className="m">10</div>
                      <div className="d">MAR</div>
                    </div>
                    <img
                      alt=""
                      src="images/news/pic-blog-1.jpg"
                      className="lazy"
                    />
                  </div>
                  <div className="post-text">
                    <h4>
                      <a href="news-single.html">
                        Enjoy Best Travel Experience
                        <span />
                      </a>
                    </h4>
                    <p>
                      Traveling is an enriching experience that allows us to
                      explore new destinations, immerse ourselves in different
                      cultures, and create lifelong memories.
                    </p>
                    <a className="btn-main" href="#">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb10">
              <div className="bloglist s2 item">
                <div className="post-content">
                  <div className="post-image">
                    <div className="date-box">
                      <div className="m">12</div>
                      <div className="d">MAR</div>
                    </div>
                    <img
                      alt=""
                      src="images/news/pic-blog-2.jpg"
                      className="lazy"
                    />
                  </div>
                  <div className="post-text">
                    <h4>
                      <a href="news-single.html">
                        The Future of Car Rent
                        <span />
                      </a>
                    </h4>
                    <p>
                      As technology continues to advance at a rapid pace, the
                      car rental industry is poised for a transformative shift.
                      The future of car rental promises..
                    </p>
                    <a className="btn-main" href="#">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb10">
              <div className="bloglist s2 item">
                <div className="post-content">
                  <div className="post-image">
                    <div className="date-box">
                      <div className="m">14</div>
                      <div className="d">MAR</div>
                    </div>
                    <img
                      alt=""
                      src="images/news/pic-blog-3.jpg"
                      className="lazy"
                    />
                  </div>
                  <div className="post-text">
                    <h4>
                      <a href="news-single.html">
                        Holiday Tips For Backpacker
                        <span />
                      </a>
                    </h4>
                    <p>
                      For adventure seekers and budget-conscious travelers,
                      backpacking offers a thrilling and immersive way to
                      explore the world. Whether you're embarking..
                    </p>
                    <a className="btn-main" href="#">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section-testimonials" className="no-top no-bottom">
        <div className="container-fluid">
          <div className="row g-2 p-2 align-items-center">
            <div className="col-md-4">
              <div className="de-image-text">
                <div className="d-text">
                  <div className="d-quote id-color">
                    <i className="fa fa-quote-right" />
                  </div>
                  <h4>Excellent Service! Car Rent Service!</h4>
                  <blockquote>
                    I have been using Rentaly for my Car Rental needs for over 5
                    years now. I have never had any problems with their service.
                    Their customer support is always responsive and helpful. I
                    would recommend Rentaly to anyone looking for a reliable Car
                    Rental provider.
                    <span className="by">Stepanie Hutchkiss</span>
                  </blockquote>
                </div>
                <img
                  src="images/testimonial/1.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="de-image-text">
                <div className="d-text">
                  <div className="d-quote id-color">
                    <i className="fa fa-quote-right" />
                  </div>
                  <h4>Excellent Service! Car Rent Service!</h4>
                  <blockquote>
                    We have been using Rentaly for our trips needs for several
                    years now and have always been happy with their service.
                    Their customer support is Excellent Service! and they are
                    always available to help with any issues we have. Their
                    prices are also very competitive.
                    <span className="by">Jovan Reels</span>
                  </blockquote>
                </div>
                <img
                  src="images/testimonial/2.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="de-image-text">
                <div className="d-text">
                  <div className="d-quote id-color">
                    <i className="fa fa-quote-right" />
                  </div>
                  <h4>Excellent Service! Car Rent Service!</h4>
                  <blockquote>
                    Endorsed by industry experts, Rentaly is the Car Rental
                    solution you can trust. With years of experience in the
                    field, we provide fast, reliable and secure Car Rental
                    services.
                    <span className="by">Kanesha Keyton</span>
                  </blockquote>
                </div>
                <img
                  src="images/testimonial/3.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section-faq">
        <Faq/>
      </section>
      <section
        id="section-call-to-action"
        className="bg-color-2 pt60 pb60 text-light"
      >
        <div className="container">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 offset-lg-2">
                <span className="subtitle text-white">
                  Call us for further information
                </span>
                <h2 className="s2">
                  Rentaly customer care is here to help you anytime.
                </h2>
              </div>
              <div className="col-lg-4 text-lg-center text-sm-center">
                <div className="phone-num-big">
                  <i className="fa fa-phone" />
                  <span className="pnb-text">Call Us Now</span>
                  <span className="pnb-num">1 200 333 800</span>
                </div>
                <a href="#" className="btn-main">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
   <Footer/>
  
</>

  )
}

export default Home