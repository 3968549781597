import React, { useEffect, useState } from 'react';

function Faq() {
  const [faqs, setFaqs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null); // State to track the active accordion

  // Fetching the FAQ data using the GET method
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetch('https://apiuser.ride2luxury.in/api/faqs', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        if (data.status) {
          setFaqs(data.faq); // Store fetched FAQ data in state
        }
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      }
    };

    fetchFaqs();
  }, []);

  // Function to toggle accordion content visibility
  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle active accordion
  };

  return (
    <>
      {/* content close */}
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <span className="subtitle">Do You Have</span>
            <h2>Any Questions?</h2>
            <div className="spacer-20" />
          </div>
        </div>

        <div className="row g-custom-x">
          <div className="col-md-12 wow fadeInUp">
            <div className="accordion secondary">
              <div className="accordion-section">
                {faqs.length > 0 ? (
                  faqs.map((faq, index) => (
                    <React.Fragment key={faq.id}>
                      <div
                        className="accordion-section-title"
                        onClick={() => handleAccordionClick(index)} // Add click handler
                        style={{
                          cursor: 'pointer', // Show pointer on hover
                          padding: '10px',
                          backgroundColor: activeIndex === index ? '#f0f0f0' : '#fff', // Change background color for active
                        }}
                      >
                        {faq.question}
                      </div>
                      <div
                        className="accordion-section-content"
                        style={{
                          display: activeIndex === index ? 'block' : 'none', // Toggle content visibility
                          padding: '10px',
                          borderBottom: '1px solid #ddd', // Add border for separation
                        }}
                      >
                        <p>{faq.answer}</p>
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <p>No FAQs available at the moment.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer close */}
    </>
  );
}

export default Faq;
