import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import { BrowserRouter, Routes,Router,Route } from 'react-router-dom';
import Home from './Pages/Home';
import Home1 from './Pages/Home1';
import Home2 from './Pages/Home2';
import Home3 from './Pages/Home3';
import Home4 from './Pages/Home4';
import Home5 from './Pages/Home5';
import DarkHome from './Pages/DarkHome';
import DarkHome1 from './Pages/DarkHome1';
import Cars from './car/Cars';
import CarslistDark from './car/CarslistDark';
import Darkcarslist from './car/Darkcarslist';
import Carsingle from './car/Carsingle';
import DarkCarsingle from './car/DarkCarsingle';
import Darkcars from './car/Darkcars';
import Booking from './Pages/Booking';
import AccountDashboard from './Account/AccountDashboard';
import AccountBooking from './Account/AccountBooking';
import AccountProfile from './Account/AccountProfile';
import AccountFavorite from './Account/AccountFavorite';
import Login from './Account/Login';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Error from './Pages/Error';
import NewsStandartRightSidebar from './News/NewsStandartRightSidebar';
import NewsStandartLeftSidebar from './News/NewsStandartLeftSidebar';
import NewsStandartNoSidebar from './News/NewsStandartNoSidebar';
import NewsGridRightSidebar from './News grid/NewsGridRightSidebar';
import NewsGridLeftSidebar from './News grid/NewsGridLeftSidebar';
import NoSidebar from './News grid/NoSidebar';
import Preloader from './Elements/Preloader';
import IconBox from './Elements/IconBox';
import Badges from './Elements/Badges';
import Counters from './Elements/Counters';
import GalleryPopup from './Elements/GalleryPopup';
import IconsElegant from './Elements/IconsElegant';
import IconsEtline from './Elements/IconsEtline';
import FontAwesomeIcons from './Elements/FontAwesomeIcons';
import Map from './Elements/Map';
import Model from './Elements/Model';
import Popover from './Elements/Popover';
import Tabs from './Elements/Tabs';
import Tooltips from './Elements/Tooltips';
import Register from './Account/Register';
// import AuthUser from './Account/AuthUser';

function App() {
  // const {getToken} = AuthUser();
  // if(!getToken()){
  //   return<Login/>
  // }
  return (
   <BrowserRouter>
   <Routes>
        <Route excat path="/" element={<Home/>}/>
        <Route path="/Home1" element={<Home1/>}/>
        <Route path="/Home2" element={<Home2/>}/>
        <Route path="/Home3" element={<Home3/>}/>
        <Route path="/Home4" element={<Home4/>}/>
        <Route path="/Home5" element={<Home5/>}/>
        <Route path="/DarkHome" element={<DarkHome/>}/>
        <Route path="/DarkHome1" element={<DarkHome1/>}/>
        <Route path="/Cars" element={<Cars/>}/>
        <Route path='/CarslistDark' element={<CarslistDark/>}/>
        <Route path='/Carsingle' element={<Carsingle/>}/>
        <Route path='/Darkcarslist' element={<Darkcarslist/>}/>
        <Route path='/DarkCarsingle' element={<DarkCarsingle/>}/>
        <Route path='/Darkcars' element={<Darkcars/>}/>
        <Route path='/Booking' element={<Booking/>}/>
        <Route path='/AccountDashboard' element={<AccountDashboard/>}/>
        <Route path='/AccountBooking' element={<AccountBooking/>}/>
        <Route path='/AccountProfile' element={<AccountProfile/>}/>
        <Route path='/AccountFavorite' element={<AccountFavorite/>}/>
        <Route path='/Login' element={<Login/>}/>
        <Route path='/About' element={<About/>}/>
        <Route path='/Contact' element={<Contact/>}/>
        <Route path='/Register' element={<Register/>}/>
        <Route path='/Error' element={<Error/>}/>
        <Route path='/NewsStandartRightSidebar' element={<NewsStandartRightSidebar/>}/>
        <Route path='/NewsStandartLeftSidebar' element={<NewsStandartLeftSidebar/>}/>
        <Route path='/NewsStandartNoSidebar' element={<NewsStandartNoSidebar/>}/>
        <Route path='/NewsGridRightSidebar' element={<NewsGridRightSidebar/>}/>
        <Route path='/NewsGridLeftSidebar' element={<NewsGridLeftSidebar/>}/>
        <Route path='/NoSidebar' element={<NoSidebar/>}/>
        <Route path='/Preloader' element={<Preloader/>}/>
        <Route path='/IconBox' element={<IconBox/>}/>
        <Route path='/Badges' element={<Badges/>}/>
        <Route path='/Counters' element={<Counters/>}/>
        <Route path='/GalleryPopup' element={<GalleryPopup/>}/>
        <Route path='/IconsElegant' element={<IconsElegant/>}/>
        <Route path='/IconsEtline' element={<IconsEtline/>}/>
        <Route path='/FontAwesomeIcons' element={<FontAwesomeIcons/>}/>
        <Route path='/Map' element={<Map/>}/>
        <Route path='/Model' element={<Model/>}/>
        <Route path='/Popover' element={<Popover/>}/>
        <Route path='/Tabs' element={<Tabs/>}/>
        <Route path='/Tooltips' element={<Tooltips/>}/>
        {/* <Route path='/Map' element={<Map/>}/> */}

   </Routes>
   </BrowserRouter>
  );
}

export default App;
